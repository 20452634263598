import { graphql } from 'gatsby';
import React from 'react';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import PrivacyPolicy from '../../components/sections/privacy-policy/PrivacyPolicy';
import { AllJsonData } from '../../types/graphql';
import { PolicyContent } from '../../types/policy-content';

interface PrivacyPolicyPageProps extends AllJsonData<PolicyContent, { allJson: [] }> {}

const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = ({ data }) => {
  const privacyPolicyContent = data?.allJson.nodes || [];

  return (
    <LayoutDefault>
      <PageMetaData title="Privacy Policy" />
      <PrivacyPolicy policyContent={privacyPolicyContent} />
    </LayoutDefault>
  );
};

export const query = graphql`
  query PrivacyPolicy {
    allJson(filter: { title: { eq: "privacyPolicyContent" } }) {
      nodes {
        contentEn
        contentLt
      }
    }
  }
`;

export default PrivacyPolicyPage;
